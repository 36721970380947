<template>
  <div id="page-container">
    <div class="background" :style="{ backgroundImage: 'url( '+ require(`../assets/backgrounds/${this.$store.state.customBranding.backgroundImage}`)+' )' }"></div>    
    <div class="headerbar" :style="[ this.$store.state.customBranding.style == 'style2' ? {'background-color' : (this.$store.state.customBranding.bgColor)} : {'background-color' : ''}]"></div>
    
    <div id="headerd"
      :style="[ this.$store.state.customBranding.style == 'style2' ? {'background-color' : (this.$store.state.customBranding.bgColor)} : {'background-color' : ''}]"
      :class="[ this.$store.state.customBranding.style == 'style2'  ? 'header containergridheader2' : 'header2 containergridheader' ]">
      
      <div id="logoContainer" :class="[ this.$store.state.customBranding.style == 'style2' ? 'logocontainer2' : 'logocontainer' ]">
        <img id="nagiLogo" :src="require(`../assets/icons/${this.$store.state.customBranding.logo}`)" :alt="this.$store.state.customBranding.logo" />
      </div>

      <span class="btnsright">
        <div id="buttonLogout" class="buttonLogout text" @click="logout()" :style="{'background-color' : this.$store.state.customBranding.bgColor}"
        :class="[ this.$store.state.customBranding.style == 'style2' ? 'buttonLogout2' : '' ]">
          {{ $t("home.logout_button") | capitalize }}
        </div>
        <nagi-header :stylecss="this.$store.state.customBranding.style"/>
      </span>
    </div>
    <!-- <div>{{isNewUser}}</div> -->
    <LoadingDots v-if="lastSessionLoader" :black="true" class="loadingDots" />
    <div v-if="!lastSessionLoader" class="statsWrapper">
      <div v-if="userHasData" class="dataWrapper">
        <div class="sessionWrapper">
          <LastSession />
        </div>
        <div class="summaryWrapper">
          <Summary />
        </div>
      </div>
      <div v-if="userHasData" class="totalSessionsWrapper">
        <TotalSessions />
      </div>
      <NoData v-if="!userHasData && !lastSessionLoader && !notActiveAccount403" />
      <NotActiveAccount v-if="notActiveAccount403" />
    </div>
    <nagi-footer />
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth";

import { mapState } from "vuex";

const LastSession = () => import("../components/LastSession");
const Summary = () => import("../components/Summary");
const TotalSessions = () => import("../components/TotalSessions");
const NoData = () => import("../components/NoData");
const NotActiveAccount = () => import("../components/NotActiveAccount");
const LoadingDots = () => import("../components/LoadingDots");
const NagiHeader = () => import("../components/NagiHeader");
const NagiFooter = () => import("../components/NagiFooter");

import { isObjEmpty } from "../parsers";

export default {
  name: "Stats",
  components: {
    LoadingDots,
    LastSession,
    Summary,
    TotalSessions,
    NoData,
    NotActiveAccount,
    NagiHeader,
    NagiFooter,
  },
  data() {
    return {
      isNewUser: this.$route.params.isNewUser, //unused
    };
  },
  computed: {
    ...mapState({
      lastSession: (state) => state.lastSession,
      lastSessionLoader: (state) => state.lastSessionLoader,
      notActiveAccount403: (state) => state.notActiveAccount403,
    }),
    userHasData() {
      return !isObjEmpty(this.lastSession);
    },
  },
  created() {
    this.$store.dispatch("lastSessionLoading", true);

    this.$store.dispatch("fetchLastSession");
    this.$store.dispatch("fetchSummary", 0);
    this.$store.dispatch("fetchTotalSessions");
    // this.$store.commit('setCustomBranding');
  },
  methods: {
    logout() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$router.replace("home");
        });
    },
  },
};
</script>

<style scoped>
.statsWrapper {
  z-index: 10;
}

.headerbar{
  z-index: 2;
  height: 73px;
}
.containergridheader {
  display: grid;
  padding: 0 12%;
  z-index: 5;
  margin-top: -68px;
}
.containergridheader > * {
  grid-column-start: 1;
  grid-row-start: 1;
  justify-self: start;
}
.containergridheader > *:last-child {
  justify-self: right;
}
.containergridheader2 {
  display: grid;
  padding: 0 12%;
  z-index: 5;
}
.containergridheader2 > * {
  grid-column-start: 1;
  grid-row-start: 1;
  justify-self:center;
}
.containergridheader2 > *:last-child {
  justify-self: right;
}
.btnsright {
  display: flex;
  flex-direction: row;
}

.loadingDots {
  font-size: 5em;
  margin: auto;
}
#page-container {
  display: grid;
  grid-template-rows: 48px 1fr 8fr 72px;
  background: #eef1f3;
  min-height: 100vh;
  width: 100%;
  position: relative;
  justify-items: unset;
  overflow: unset;
  height: unset;
  min-width: unset;
}
.background {
  background-size: 100% auto;
  background-position: 100% 65%;
}
#logoContainer {
  color: white;
  height: fit-content;
}
.buttonLogout {
  align-self: center;
  cursor: pointer;
  font-size: 0.875rem;
  background-color: #030d2c;
  padding: 1rem;
  box-shadow: 1px 1px 1px white;
  color: #e1e1e1;
}
.buttonLogout2 {
  align-self: center;
  cursor: pointer;
  font-size: 0.875rem;
  background-color: #030d2c;
  padding: 0.5rem;
  box-shadow: 0px 0px 0px white;
  border-right: #fff 1px solid;
  color: #fff;
}
.title {
  font-weight: 500;
  font-size: 1.5rem;
  margin-bottom: 0;
  align-self: center;
}
.totalTitle {
  margin: 6vh;
}
#header {
  z-index: 1;
  display: flex;
  flex-direction: row;
  color: white;
  justify-content: space-between;
  padding: 0 12%;
  width: 100%;
  width: stretch;
}
.header {
  height: 40px;
  overflow: visible;
  z-index: 6;
  margin-top: -31px;
}
.header2 {
  height: 40px;
  overflow: visible;
  z-index: 6;
  margin-top: -31px;
}
.header2 #header-wrapper {
    margin-top: -11px;
    margin-left: 10px;
  }
.background {
  width: 100vw;
  height: 30vh;
  overflow: hidden;
  position: absolute;
}
#logoContainer {
  height: fit-content;
  max-width: fit-content;
  align-self: center;
}
#nagiLogo {
  max-width: 100%;
}
.dataWrapper {
  z-index: 1;
  display: flex;
  justify-content: space-between;
  padding: 0 8%;
  min-height: 20rem;
  max-width: 100%;
  height: fit-content;
  margin: auto;
}
.sessionWrapper {
  display: flex;
  width: 35%;
}
.summaryWrapper {
  width: 62%;
}
.totalSessionsWrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  width: 100%;
  margin-bottom: 10vh;
  margin: auto;
}
.totalSessionsWrapper p {
  color: black;
}

@media screen and (max-width: 1024px) {
  .background {
    background-size: 200% auto;
    background-position: 50%;
    position: fixed;
    z-index: 1;
  }
  #header {
    display: flex;
    flex-direction: row;
    padding: 4% 4% 0 4%;
    margin-bottom: auto;
  }
  .header {
    margin-top: -37px;
    z-index: 10;
    position: relative;
  }
  .header2 {
    margin-top: -37px;
    z-index: 10;
    position: relative;
  }
  .header2 #logoContainer {
    margin-top: 38px;
  }
  .header2 #buttonLogout  {
    margin-top: -23px;
  }
  .header2 #header-wrapper {
    margin-top: -35px;
    margin-left: 10px;
  }

  .logocontainer {
    align-self: center !important;
    width: 7em !important;
    margin-top: -50px;
  }
  .logocontainer img {
     width: 7em !important;
  }
  .logocontainer2 {
    align-self: center !important;
    width: 5em !important;
    margin-top: -5px;
  }
  .logocontainer2 img {
     width: 5em !important;
  }
  #logoContainer,
  .buttonLogout {
    align-self: flex-start;
  }
  #logoContainer img {
    width: 4em;
  }
  .title {
    font-size: 1rem;
    padding-bottom: 6%;
  }
  .buttonLogout {
    margin: 0;
  }
  .dataWrapper {
    flex-direction: column;
    width: 92vw;
    padding: 0 4%;
  }
  .sessionWrapper {
    width: 100%;
  }
  .summaryWrapper {
    margin-top: 3rem;
    width: 100%;
    width: stretch;
  }
  .totalTitle {
    padding: 0;
    font-size: 1.3rem;
    margin: 3.5rem 0 2rem;
  }
  .totalSessionsWrapper {
    margin-bottom: 5rem;
  }
  .containergridheader2 > * {
    justify-self: start;
    padding-top: 19px;
  }
  .btnsright {
    padding-top: 8px !important;
  }
  .statsWrapper{
    z-index: 4;
    position: relative;
    margin-top: 110px;
  }
  .headerbar{
    position: relative; 
    z-index: 2;
  }
}
@media screen and (max-width: 480px) {
  .header {
    margin-top: -58px;
  }
  .header2 {
    margin-top: -84px;
  }
  .header2 #logoContainer {
    margin-top: 38px;
  }
  .header2 #buttonLogout  {
    margin-top: 0px;
  }
  .header2 #header-wrapper {
    margin-top: -13px;
    margin-left: 10px;
  }
  .statsWrapper{
    margin-top: 172px;
  }
}
</style>
